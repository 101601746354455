function PaymentTerms({
  index,
  paymentTermMode,
  paymentDayOfMonth,
  forecastPaymentDays,
  onModeChange,
  onDayOfMonthChange,
  onForecastDaysChange,
}) {
  return (
    <div className="flex items-center space-x-2">
      <div className="flex items-start flex-col space-y-1 text-sm">
        <label className="flex items-center">
          <input
            type="radio"
            name={`paymentTermMode-${index}`}
            checked={paymentTermMode === "dayOfMonth"}
            onChange={() => onModeChange("dayOfMonth")}
            className="form-radio h-4 w-4 mr-1"
          />
          <span className="text-xs whitespace-nowrap">Day of Month</span>
        </label>
        <label className="flex items-center">
          <input
            type="radio"
            name={`paymentTermMode-${index}`}
            checked={paymentTermMode === "daysAfterMonthEnd"}
            onChange={() => onModeChange("daysAfterMonthEnd")}
            className="form-radio h-4 w-4 mr-1"
          />
          <span className="text-xs whitespace-nowrap">Days After</span>
        </label>
      </div>

      {paymentTermMode === "dayOfMonth" ? (
        <input
          type="number"
          min="1"
          max="31"
          className="p-2 text-black text-sm rounded-lg border border-gray-300 w-20 h-12"
          placeholder="e.g. 25"
          value={paymentDayOfMonth}
          onChange={(e) => onDayOfMonthChange(e.target.value)}
        />
      ) : (
        <input
          type="number"
          min="0"
          className="p-2 text-black text-sm rounded-lg border border-gray-300 w-20 h-12"
          placeholder="e.g. 30"
          value={forecastPaymentDays}
          onChange={(e) => onForecastDaysChange(e.target.value)}
        />
      )}
    </div>
  );
}

export default PaymentTerms;
