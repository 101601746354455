import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as XLSX from "xlsx";

const ProfitLossView = ({ costs, income }) => {
  const calculateYearlyTotal = (entries) => {
    return entries.reduce((total, entry) => {
      return (
        total + entry.amounts.reduce((sum, amount) => sum + (amount || 0), 0)
      );
    }, 0);
  };

  const totalSales = calculateYearlyTotal(income);
  const totalCosts = costs.reduce((total, cost) => {
    return total + cost.amounts.reduce((sum, amount) => sum + (amount || 0), 0);
  }, 0);
  const operatingProfit = totalSales - totalCosts;

  const formatNumber = (amount) => {
    return Math.round(amount).toLocaleString("en-GB");
  };

  // Function to download Excel file
  const downloadProfitLoss = () => {
    const workbook = XLSX.utils.book_new();

    // Turnover Sheet
    const turnoverSheet = [
      ["Category", "Amount (£)"],
      ["Sales", totalSales],
      ["Total Turnover", totalSales],
    ];
    const turnoverWS = XLSX.utils.aoa_to_sheet(turnoverSheet);
    XLSX.utils.book_append_sheet(workbook, turnoverWS, "Turnover");

    // Cost of Sales Sheet
    const costOfSalesSheet = [
      ["Category", "Amount (£)"],
      ["Cost of Goods Sold", totalCosts],
      ["Total Cost of Sales", totalCosts],
    ];
    const costOfSalesWS = XLSX.utils.aoa_to_sheet(costOfSalesSheet);
    XLSX.utils.book_append_sheet(workbook, costOfSalesWS, "Cost of Sales");

    // Administrative Costs Sheet
    const administrativeSheet = [["Supplier", "Amount (£)"]];
    costs
      .filter((cost) => cost.category === "administrative")
      .forEach((cost) => {
        const total = cost.amounts.reduce(
          (sum, amount) => sum + (amount || 0),
          0
        );
        administrativeSheet.push([cost.supplierName || "Unnamed Cost", total]);
      });
    administrativeSheet.push([
      "Total Administrative Costs",
      totalCosts - totalCosts,
    ]);
    const administrativeWS = XLSX.utils.aoa_to_sheet(administrativeSheet);
    XLSX.utils.book_append_sheet(
      workbook,
      administrativeWS,
      "Administrative Costs"
    );

    // Summary Sheet
    const summarySheet = [
      ["Category", "Amount (£)"],
      ["Gross Profit", totalSales - totalCosts],
      ["Operating Profit", operatingProfit],
      ["Profit Before Tax", operatingProfit],
      ["Profit After Tax", operatingProfit],
    ];
    const summaryWS = XLSX.utils.aoa_to_sheet(summarySheet);
    XLSX.utils.book_append_sheet(workbook, summaryWS, "Summary");

    // Download the file
    XLSX.writeFile(workbook, "Profit_Loss_Report.xlsx");
  };

  return (
    <div className="max-w-6xl mx-auto bg-white p-6 text-left shadow-lg rounded-lg">
      <h1 className="text-2xl font-bold mb-4 text-center">Profit and Loss</h1>

      {/* Turnover Section */}
      <section>
        <h2 className="text-lg font-semibold border-b pb-1">Turnover</h2>
        <div className="grid grid-cols-2 py-1">
          <span className="text-sm">Sales</span>
          <span className="text-right text-sm">{formatNumber(totalSales)}</span>
        </div>
        <div className="grid grid-cols-2 py-1 font-bold border-t">
          <span className="text-sm">Total Turnover</span>
          <span className="text-right text-sm">{formatNumber(totalSales)}</span>
        </div>
      </section>

      {/* Costs Section */}
      <section className="mt-4">
        <h2 className="text-lg font-semibold border-b pb-1">Costs</h2>
        {costs.map((cost, index) => (
          <div key={index} className="grid grid-cols-2 py-1">
            <span className="text-sm capitalize">
              {cost.category.replace(/([A-Z])/g, " $1").trim()}
            </span>
            <span className="text-right text-sm">
              {formatNumber(
                cost.amounts.reduce((sum, amount) => sum + (amount || 0), 0)
              )}
            </span>
          </div>
        ))}
        <div className="grid grid-cols-2 py-1 font-bold border-t">
          <span className="text-sm">Total Costs</span>
          <span className="text-right text-sm">{formatNumber(totalCosts)}</span>
        </div>
      </section>

      {/* Operating Profit */}
      <section className="mt-4">
        <div className="grid grid-cols-2 py-1 font-bold border-t border-b">
          <span className="text-sm">Operating Profit</span>
          <span className="text-right text-sm">
            {formatNumber(operatingProfit)}
          </span>
        </div>
      </section>

      {/* Profit before and after Tax */}
      <section className="mt-4">
        <div className="grid grid-cols-2 py-1 font-bold border-b">
          <span className="text-sm">
            Profit on Ordinary Activities Before Taxation
          </span>
          <span className="text-right text-sm">
            {formatNumber(operatingProfit)}
          </span>
        </div>
        <div className="grid grid-cols-2 py-1 font-bold">
          <span className="text-sm">Profit after Taxation</span>
          <span className="text-right text-sm">
            {formatNumber(operatingProfit)}
          </span>
        </div>
      </section>

      {/* Download Button */}
      <button
        className="mt-4 p-2 h-12 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
        onClick={downloadProfitLoss}
      >
        <FontAwesomeIcon icon={faDownload} />
      </button>
    </div>
  );
};

export default ProfitLossView;
