import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent, faPoundSign } from "@fortawesome/free-solid-svg-icons";

const FILL_METHODS = {
  SAME: "same",
  FIXED: "fixed",
  PERCENTAGE: "percentage",
};

function FillForwardModal({
  showModal,
  startMonth,
  currentAmount,
  onClose,
  onApply,
}) {
  const [fillMethod, setFillMethod] = useState(FILL_METHODS.SAME);
  const [incrementValue, setIncrementValue] = useState("");
  const [percentValue, setPercentValue] = useState("");

  if (!showModal) return null;

  const handleApply = () => {
    onApply({
      fillMethod,
      incrementValue: parseFloat(incrementValue) || 0,
      percentValue: parseFloat(percentValue) || 0,
    });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h3 className="text-black text-lg font-semibold mb-4">
          Fill Forward From Month {startMonth + 1}
        </h3>

        <div className="space-y-4">
          <div className="space-y-2">
            <label className="flex items-center space-x-2">
              <input
                type="radio"
                checked={fillMethod === FILL_METHODS.SAME}
                onChange={() => setFillMethod(FILL_METHODS.SAME)}
                className="form-radio"
              />
              <span className="text-sm text-gray-700">
                Same as current month amount (£{currentAmount})
              </span>
            </label>

            <label className="flex items-center space-x-2">
              <input
                type="radio"
                checked={fillMethod === FILL_METHODS.FIXED}
                onChange={() => setFillMethod(FILL_METHODS.FIXED)}
                className="form-radio"
              />
              <span className="text-sm text-gray-700 flex items-center">
                Increase by
                <FontAwesomeIcon icon={faPoundSign} className="mx-1" />
                <input
                  type="number"
                  className="w-24 p-1 ml-1 border rounded"
                  placeholder="amount"
                  value={incrementValue}
                  onChange={(e) => setIncrementValue(e.target.value)}
                  disabled={fillMethod !== FILL_METHODS.FIXED}
                />
                each month
              </span>
            </label>

            <label className="flex items-center space-x-2">
              <input
                type="radio"
                checked={fillMethod === FILL_METHODS.PERCENTAGE}
                onChange={() => setFillMethod(FILL_METHODS.PERCENTAGE)}
                className="form-radio"
              />
              <span className="text-sm text-gray-700 flex items-center">
                Increase by
                <input
                  type="number"
                  className="w-16 p-1 mx-1 border rounded"
                  placeholder="%"
                  value={percentValue}
                  onChange={(e) => setPercentValue(e.target.value)}
                  disabled={fillMethod !== FILL_METHODS.PERCENTAGE}
                />
                <FontAwesomeIcon icon={faPercent} />
                each month
              </span>
            </label>
          </div>
        </div>

        <div className="flex justify-end space-x-3 mt-6 pt-4 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
          <button
            onClick={handleApply}
            className="px-4 py-2 text-sm bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}

export default FillForwardModal;
