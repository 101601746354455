import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faListCheck,
} from "@fortawesome/free-solid-svg-icons";

const SIMPLIFIED_EXPENSES = [
  "costOfGoodsSold",
  "advertisingMarketing",
  "auditAccountancy",
  "bankFees",
  "charitableDonations",
  "postageFreight",
  "generalExpenses",
  "insurance",
  "motorVehicleEquipment",
  "printStationary",
  "itSoftwareConsumables",
  "repairsMaintenance",
  "subscriptions",
  "telephoneInternet",
  "travelNational",
];

const EXTENDED_EXPENSES = [
  "costOfGoodsSold",
  "directWages",
  "directExpenses",
  "advertisingMarketing",
  "auditAccountancy",
  "bankFees",
  "charitableDonations",
  "entertainment",
  "postageFreight",
  "generalExpenses",
  "insurance",
  "interestPaid",
  "legalExpenses",
  "lightPowerHeating",
  "motorVehicleExpenses",
  "printStationary",
  "itSoftwareConsumables",
  "rates",
  "rent",
  "repairsMaintenance",
  "salaries",
  "employersNationalInsurance",
  "pensionsCosts",
  "subscriptions",
  "telephoneInternet",
  "travelNational",
  "corporationTax",
];

// Add a mapping object for readable labels
export const EXPENSE_LABELS = {
  costOfGoodsSold: "Cost of Goods Sold",
  directWages: "Direct Wages",
  directExpenses: "Direct Expenses",
  advertisingMarketing: "Advertising & Marketing",
  auditAccountancy: "Audit & Accountancy Fees",
  bankFees: "Bank Fees",
  charitableDonations: "Charitable and Political Donations",
  entertainment: "Entertainment",
  postageFreight: "Postage, Freight & Courier",
  generalExpenses: "General Expenses",
  insurance: "Insurance",
  interestPaid: "Interest Paid",
  legalExpenses: "Legal Expenses",
  lightPowerHeating: "Light, Power & Heating",
  motorVehicleExpenses: "Motor Vehicle Expenses",
  motorVehicleEquipment: "Motor Vehicle Equipment",
  printStationary: "Printing & Stationery",
  itSoftwareConsumables: "IT Software and Consumables",
  rates: "Rates",
  rent: "Rent",
  repairsMaintenance: "Repairs & Maintenance",
  salaries: "Salaries",
  employersNationalInsurance: "Employers National Insurance",
  pensionsCosts: "Pensions Costs",
  subscriptions: "Subscriptions",
  telephoneInternet: "Telephone & Internet",
  travelNational: "Travel - National",
  corporationTax: "Corporation Tax",
};

function ExpenseTypes({ documentId, onBack, onSelect }) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleContinue = () => {
    if (selectedOption === "simplified") {
      const templateCosts = SIMPLIFIED_EXPENSES.map((category) => ({
        supplierName: "",
        VATable: "Yes",
        forecastPaymentDays: 0,
        category,
        subcategory: "Overhead",
        paymentTerms: "immediate",
        amounts: Array(12).fill(0),
      }));
      onSelect(templateCosts, SIMPLIFIED_EXPENSES);
    } else if (selectedOption === "extended") {
      const templateCosts = EXTENDED_EXPENSES.map((category) => ({
        supplierName: "",
        VATable: "Yes",
        forecastPaymentDays: 0,
        category,
        subcategory: "Overhead",
        paymentTerms: "immediate",
        amounts: Array(12).fill(0),
      }));
      onSelect(templateCosts, EXTENDED_EXPENSES);
    } else {
      // Empty template with no predefined categories
      onSelect([], []);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-2xl mx-auto">
        <button
          onClick={onBack}
          className="mb-6 flex items-center text-white hover:text-gray-300 transition-colors"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          Back
        </button>

        <div className="bg-white rounded-lg p-8 shadow-lg">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">
            <FontAwesomeIcon icon={faListCheck} className="mr-4" />
            Need a template of expense types?
          </h2>

          <div className="space-y-4 text-gray-900 mb-6">
            {[
              {
                id: "simplified",
                label: "Simplified",
                description: `${SIMPLIFIED_EXPENSES.length} most common expenses`,
              },
              {
                id: "extended",
                label: "Extended",
                description: `${EXTENDED_EXPENSES.length} expense types`,
              },
              {
                id: "custom",
                label: "Enter all expenses myself",
                description: "Start with a blank template",
              },
            ].map((option) => (
              <button
                key={option.id}
                onClick={() => handleOptionSelect(option.id)}
                className={`w-full p-4 text-left rounded-lg border-2 transition-all ${
                  selectedOption === option.id
                    ? "border-blue-500 bg-blue-50"
                    : "border-gray-200 hover:border-blue-300"
                }`}
              >
                <div className="font-semibold">{option.label}</div>
                <div className="text-sm text-gray-600">
                  {option.description}
                </div>
              </button>
            ))}
          </div>

          {selectedOption && (
            <button
              onClick={handleContinue}
              className="w-full p-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg flex items-center justify-center transition-colors"
            >
              Continue
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ExpenseTypes;
