import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function VatSetup({ documentId, onBack, onSave }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [showMonths, setShowMonths] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (option !== "partYear") {
      setShowMonths(false);
      setSelectedMonth(null);
    } else {
      setShowMonths(true);
    }
  };

  const handleSave = async () => {
    if (!selectedOption) return;

    const vatSetup = {
      vatRegistered: selectedOption !== "no",
      vatPartYear: selectedOption === "partYear",
      vatStarting: selectedMonth || null,
      vatableIncome: selectedOption === "no" ? false : null,
    };

    try {
      const projectRef = doc(db, "projects", documentId);
      await updateDoc(projectRef, { vatSetup });
      onSave(vatSetup);
    } catch (e) {
      console.error("Error updating VAT setup:", e);
    }
  };

  const canProceed =
    selectedOption && (selectedOption !== "partYear" || selectedMonth);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-2xl mx-auto">
        <button
          onClick={onBack}
          className="mb-6 flex items-center text-white hover:text-gray-300 transition-colors"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
          Back
        </button>

        <div className="bg-white rounded-lg p-8 shadow-lg">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">
            Is your business VAT registered?
          </h2>

          <div className="space-y-4 text-gray-900 mb-6">
            {[
              { id: "no", label: "No" },
              { id: "yes", label: "Yes" },
              { id: "partYear", label: "Yes (Part way through the year)" },
            ].map((option) => (
              <button
                key={option.id}
                onClick={() => handleOptionSelect(option.id)}
                className={`w-full p-4 text-left rounded-lg border-2 transition-all ${
                  selectedOption === option.id
                    ? "border-blue-500 bg-blue-50"
                    : "border-gray-200 hover:border-blue-300"
                }`}
              >
                {option.label}
              </button>
            ))}
          </div>

          {showMonths && (
            <div className="mb-6  text-gray-800">
              <h3 className="text-lg font-semibold mb-4">
                Select VAT registration start month:
              </h3>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
                {months.map((month) => (
                  <button
                    key={month}
                    onClick={() => setSelectedMonth(month)}
                    className={`p-3 rounded-lg border-2 transition-all ${
                      selectedMonth === month
                        ? "border-blue-500 bg-blue-50"
                        : "border-gray-200 hover:border-blue-300"
                    }`}
                  >
                    {month}
                  </button>
                ))}
              </div>
            </div>
          )}

          {canProceed && (
            <button
              onClick={handleSave}
              className="w-full p-4 bg-blue-600 hover:bg-blue-700 text-white rounded-lg flex items-center justify-center transition-colors"
            >
              Next
              <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default VatSetup;
