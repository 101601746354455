export const COST_TYPES = {
  FIXED: "fixed",
  VARIABLE: "variable",
  PERCENTAGE: "percentage",
};

export const VARIABLE_INPUT_TYPES = {
  MANUAL: "manual",
  AMOUNT_INCREASE: "amount",
  PERCENTAGE_INCREASE: "percentage",
  CUSTOM: "custom",
};
