import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faArrowRight,
  faCircleInfo,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { EXPENSE_LABELS } from "../ExpenseTypes";
import CostTypeSelector from "./CostTypeSelector";

function CostsTable({
  costs,
  costsNeedingType,
  availableCategories,
  onInputChange,
  onAmountChange,
  onDeleteCost,
  onCostTypeSelect,
  onCopyPreviousMonth,
  onResetCost,
}) {
  const renderCostRow = (cost, index) => {
    const formatAmount = (value) => {
      if (value === "" || value === undefined) return "";
      return Math.round(value);
    };

    return (
      <tr key={index}>
        <td className="px-1 py-2">
          <div className="p-2 text-sm text-black w-fit">
            {EXPENSE_LABELS[cost.category] || cost.category}
          </div>
        </td>
        <td className="px-1 py-2">
          <select
            className="p-2 text-sm text-black rounded-lg border border-gray-300 w-fit h-12"
            value={cost.VATable}
            onChange={(e) => onInputChange(index, "VATable", e.target.value)}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </td>
        <td className="px-1 py-2">
          <input
            className="p-2 text-black text-sm rounded-lg border border-gray-300 w-full h-12"
            type="number"
            placeholder="e.g. 30 for end of next month"
            value={cost.forecastPaymentDays}
            onChange={(e) =>
              onInputChange(
                index,
                "forecastPaymentDays",
                e.target.value ? parseInt(e.target.value) : ""
              )
            }
          />
        </td>
        <td className="px-1 py-2">
          <div className="flex space-x-2">
            <button
              onClick={() => onResetCost(index)}
              className="text-xs text-blue-500"
              title="Reset row"
            >
              <FontAwesomeIcon icon={faRotateLeft} />
            </button>
            <button
              onClick={() => onDeleteCost(index)}
              className="text-xs text-red-500"
              title="Delete row"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </td>

        {costsNeedingType.includes(index) ? (
          <td colSpan={12} className="px-4 py-2">
            <CostTypeSelector
              onSelect={(type) => onCostTypeSelect(index, type)}
            />
          </td>
        ) : cost.isFixedMonthly ? (
          <td colSpan={12} className="px-1 py-2">
            <div className="flex items-center space-x-2">
              <input
                className="p-2 text-sm text-black rounded-lg border border-gray-300 w-full h-12"
                type="number"
                step="1"
                min="0"
                onKeyDown={(e) => {
                  if (e.key === "." || e.key === ",") {
                    e.preventDefault();
                  }
                }}
                placeholder="Enter fixed monthly amount"
                value={formatAmount(cost.fixedAmount)}
                onChange={(e) => onAmountChange(index, 0, e.target.value)}
              />
            </div>
          </td>
        ) : (
          // Regular monthly inputs
          Array.from({ length: 12 }, (_, i) => (
            <td key={i} className="pl-1 py-2">
              <div className="flex items-center">
                <input
                  className="p-2 text-sm text-black rounded-lg border border-gray-300 w-full h-12"
                  type="number"
                  step="1"
                  min="0"
                  onKeyDown={(e) => {
                    if (e.key === "." || e.key === ",") {
                      e.preventDefault();
                    }
                  }}
                  value={formatAmount(cost.amounts[i])}
                  onChange={(e) => onAmountChange(index, i, e.target.value)}
                />
                {i < 11 && (
                  <button
                    className="pl-1 text-gray-400 hover:text-green-500 transition-colors"
                    onClick={() => onCopyPreviousMonth(index, i + 1)}
                    title="Copy this value to next month"
                  >
                    <FontAwesomeIcon icon={faArrowRight} className="h-3 w-3" />
                  </button>
                )}
              </div>
            </td>
          ))
        )}
      </tr>
    );
  };

  return (
    <div className="overflow-x-auto bg-gray-50 rounded-lg text-black p-2 my-4">
      <table className="min-w-full table-auto">
        <thead>
          <tr>
            <th className="px-1 py-2 w-24 text-sm font-semibold text-left">
              Category
            </th>
            <th className="px-1 py-2 w-10 text-xs font-semibold text-left">
              VATable
            </th>
            <th className="px-1 py-2 w-10 text-xs font-semibold text-left">
              <div className="flex items-center gap-1 group relative">
                Terms
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="text-gray-600 hover:text-gray-600 cursor-help h-3 w-3"
                />
                <div className="absolute left-0 top-6 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 w-48 z-10">
                  When do you expect to make payment?
                  <div className="absolute -top-1 left-[10%] w-2 h-2 bg-gray-800 rotate-45" />
                </div>
              </div>
            </th>
            <th className="px-1 py-2 w-10 text-xs font-semibold text-left"></th>
            {costsNeedingType.length === 0 ? (
              Array.from({ length: 12 }, (_, i) => (
                <th key={i} className="px-1 py-2 text-xs font-light text-left">
                  Month {i + 1}
                </th>
              ))
            ) : (
              <th
                className="px-6 py-2 text-xs font-light text-left"
                colSpan={12}
              >
                Cost Type
              </th>
            )}
          </tr>
        </thead>
        <tbody>{costs.map((cost, index) => renderCostRow(cost, index))}</tbody>
      </table>
    </div>
  );
}

export default CostsTable;
