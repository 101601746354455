import { EXPENSE_LABELS } from "../ExpenseTypes";

function CategorySelectionModal({ isOpen, onClose, onSelect }) {
  if (!isOpen) return null;

  return (
    <div className="fixed text-gray-900 inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h2 className="text-xl font-semibold mb-4">Select Expense Category</h2>
        <div className="grid grid-cols-1 gap-1 max-h-[60vh] overflow-y-auto">
          {Object.entries(EXPENSE_LABELS).map(([key, label]) => (
            <button
              key={key}
              onClick={() => {
                onSelect(key);
                onClose();
              }}
              className="text-left border-gray-200 border  p-3 hover:bg-gray-100 hover:border-gray-800 rounded-lg transition-colors"
            >
              {label}
            </button>
          ))}
        </div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default CategorySelectionModal;
